import React from "react";
import Title from "./Title";
import Nav from "./Nav";
import { headerHolder } from "./Header.module.scss";

export default function Header({ isHome }) {
  return (
    <header className={headerHolder}>
      <Title isHome={isHome} />
      <Nav />
    </header>
  );
}
