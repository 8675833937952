import React, { useState } from "react";
import { Link } from "gatsby";
import Socials from "../Socials";
import { navHolder } from "./Nav.module.scss";
import "../../styles/hamburgers.min.css";
import useIsSmallScreen from "../../hooks/useIsSmallScreen";

export default function Nav() {
  const [navOpen, setNavOpen] = useState(false);
  const isSmallScreen = useIsSmallScreen(600);

  return (
    <nav className={navHolder}>
      {isSmallScreen && (
        <div className="burger">
          <button
            className={`hamburger hamburger--slider ${navOpen ? "is-active" : ""}`}
            type="button"
            onClick={() => setNavOpen(!navOpen)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner">Toggle Nav</span>
            </span>
          </button>
        </div>
      )}

      {(!isSmallScreen || navOpen) && (
        <div className={`headerNavHolders ${isSmallScreen ? "smallScreenNavHolder" : ""}`}>
          <ul className={`mainNav`}>
            <li>
              <Link to="/" title="Home" activeClassName="active" onClick={() => setNavOpen(false)}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" title="About" activeClassName="active" onClick={() => setNavOpen(false)}>
                About
              </Link>
            </li>
            <li>
              <Link to="/events" title="Events" activeClassName="active" onClick={() => setNavOpen(false)}>
                Events
              </Link>
            </li>
            <li>
              <Link
                to="/orchidproject"
                title="Orchid Project"
                activeClassName="active"
                onClick={() => setNavOpen(false)}
              >
                Orchid Project
              </Link>
            </li>
            <li>
              <Link to="/contact" title="Contact" activeClassName="active" onClick={() => setNavOpen(false)}>
                Contact
              </Link>
            </li>
          </ul>
          <Socials />
        </div>
      )}
    </nav>
  );
}
