import React from "react";
import Logo from "../Logo";
import Socials from "../Socials";
import { footerHolder } from "./Footer.module.scss";

export default function Footer() {
  return (
    <footer className={footerHolder}>
      <div className="upperFooter">
        <Socials />
      </div>
      <div className="lowerFooter">
        <div className="logo">
          <Logo />
        </div>
        <div className="credit">
          <a href="mailto:ben@lookupstudios.co.uk">Website by Look Up!</a>
        </div>
      </div>
    </footer>
  );
}
