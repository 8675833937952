import React from "react";
import { FaFacebook, FaTwitter, FaSpotify, FaEnvelope, FaInstagram } from "react-icons/fa";
import { socialsHolder } from "./Socials.module.scss";

export default function Socials() {
  return (
    <ul className={socialsHolder}>
      <li>
        <a href="https://www.facebook.com/wearegirlsgirlsgirlsuk" title="Girls Girls Girls on Facebook">
          <FaFacebook />
          <span className="label">Facebook</span>
        </a>
      </li>
      <li>
        <a href="https://twitter.com/ggg_uk" title="Girls Girls Girls on Twitter">
          <FaTwitter />
          <span className="label">Twitter</span>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/wearegirlsgirlsgirls" title="Girls Girls Girls on Instagram">
          <FaInstagram />
          <span className="label">Instagram</span>
        </a>
      </li>
      <li>
        <a
          href="https://open.spotify.com/playlist/2YhgVqViIHrN0GmmrD4eJq?si=7oP-n0pxQXehcOGAWYDtyQ"
          title="Girls Girls Girls on Spotify"
        >
          <FaSpotify />
          <span className="label">Spotify</span>
        </a>
      </li>
      <li>
        <a href="mailto:wearegirlsgirlsgirls@gmail.com" title="Email Girls Girls Girls">
          <FaEnvelope />
          <span className="label">Email</span>
        </a>
      </li>
    </ul>
  );
}
