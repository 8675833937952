import React from "react";
import Wrap from "../Wrap";
import Logo from "../Logo";
import { titleHolder } from "./Title.module.scss";

export default function Title({ isHome }) {
  return (
    <div className={titleHolder}>
      <Wrap
        condition={isHome}
        wrapper={(children) => <h1>{children}</h1>}
        altWrapper={(children) => <h2>{children}</h2>}
      >
        Girls Girls Girls
      </Wrap>
      <Logo />
    </div>
  );
}
