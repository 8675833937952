import React from "react";
import Header from "./Header";
import Footer from "./Footer";

import "../../styles/normalize.css";
import "../../styles/styles.scss";

export default function Layout({ children }) {
  return (
    <div className="pageHolder">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
}
